import type { FC, ReactElement } from "react"
import React, { useEffect, useMemo } from "react"
import type { StackProps } from "@chakra-ui/react"
import {
  Grid,
  Text,
  Link as ChakraLink,
  Flex,
  Stack,
  HStack,
  Skeleton,
  Button,
  useClipboard,
  Icon,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Spacer,
} from "@chakra-ui/react"
import { isAddress } from "@ethersproject/address"
import truncate from "truncate"
import { BigNumber } from "@ethersproject/bignumber"
import { useRouter } from "next/router"

import type { Governor, Organization } from "query/graphql"
import {
  useAddressHeaderQuery,
  useAddressDelegatingToQuery,
} from "query/graphql"
import { isSameAddress } from "web3/helpers/address"
import TwitterIcon from "ui/components/icons/TwitterIcon"
import ErrorBoundary from "common/components/ErrorBoundary"
import UserAvatar from "common/components/UserAvatar"
import Link from "common/components/Link"
import { EXTERNAL_ROUTES, ROUTES } from "common/constants/routes"
import { useDevice } from "common/hooks/useDevice"
import { useToast } from "common/hooks/useToast"
import CardContainer from "common/components/CardContainer"
import { shortString } from "common/helpers/string"
import AddressInfo from "common/components/AddressInfo"
import { Copy } from "ui/components/icons/font-awesome/Copy"
import { useLocation } from "common/hooks/useLocation"
import { useMe } from "user/providers/MeProvider"
import { useSession } from "session/providers/SessionProvider"
import DelegateButton from "delegation/components/DelegateButton"
import DelegationEligibleBanner from "delegation/components/DelegationEligibleBanner"
import { getWeightLabel } from "common/helpers/bignumber"
import { addressToAccountId } from "web3/helpers/transformers"
import { getMainnetReference } from "web3/helpers/chainReference"
import CopyButton from "common/components/CopyButton"
import ShareToFarcasterButton from "common/components/ShareToFarcasterButton"
import { getDelegateOrgSettings } from "delegation/constants/delegate"
import MarkdownViewer from "common/components/MarkdownViewer"
import { TokenChainIdentity } from "common/components/TokenChainIdentity"
import { isMultiChainTokenIds } from "organization/helpers/organization"
import ShareToLensButton from "common/components/ShareToLensButton"
import { ShareNodes } from "ui/components/icons/font-awesome/ShareNodes"

type Props = {
  voterAddress: string
  overridePicture?: string
  displayOptions?: boolean
  overrideName?: string
  governor?: Governor | null
  governors?: Governor[]
  organization?: Organization
  isWhiteLabel?: boolean
  displayEligibleBanner?: boolean
  slug?: string
} & StackProps

const AddressHeader: FC<Props> = ({
  voterAddress,
  displayOptions = true,
  overridePicture,
  overrideName,
  isWhiteLabel,
  governor,
  governors,
  organization,
  slug,
  displayEligibleBanner = true,
  ...stackProps
}) => {
  const me = useMe()

  const router = useRouter()
  const { query } = router

  const registerAsDelegateDisclosure = useDisclosure()

  useEffect(() => {
    if (query?.action === "register-as-delegate") {
      registerAsDelegateDisclosure.onOpen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <ErrorBoundary
        context={{
          session: me?.address,
          account: { address: voterAddress },
        }}
        section="AddressHeader"
      >
        <CardContainer pt={{ base: 4, md: 2 }}>
          <Section
            displayEligibleBanner={displayEligibleBanner}
            displayOptions={displayOptions}
            governor={governor}
            governors={governors}
            isWhiteLabel={isWhiteLabel}
            organization={organization}
            overrideName={overrideName}
            overridePicture={overridePicture}
            slug={slug}
            voterAddress={voterAddress}
            {...stackProps}
          />
        </CardContainer>
      </ErrorBoundary>

      {slug ? (
        <RegisterAsDelegateModal
          address={voterAddress}
          isOpen={registerAsDelegateDisclosure.isOpen}
          organization={organization}
          slug={slug}
          onClose={() => registerAsDelegateDisclosure.onClose()}
        />
      ) : null}
    </>
  )
}

export default AddressHeader

const Section: FC<Props> = ({
  voterAddress,
  displayOptions,
  overridePicture,
  overrideName,
  isWhiteLabel,
  governor,
  slug,
  organization,
  displayEligibleBanner,
  ...stackProps
}) => {
  const me = useMe()
  const { onLargeDevice, onLittleDevice } = useDevice()
  const { origin } = useLocation()
  const { toast } = useToast()
  const { onCopy: onCopyAddress } = useClipboard(voterAddress)

  const canDelegate = organization && organization?.tokenIds?.length > 0

  const getProfileUrl = () => {
    if (isWhiteLabel) return ROUTES.dao.delegate(voterAddress)

    return slug
      ? ROUTES.governance.delegate.profile(slug, voterAddress)
      : ROUTES.profile(voterAddress)
  }

  const profileUrl = getProfileUrl()

  const { onCopy: onCopyProfile, setValue } = useClipboard(profileUrl)

  const { isLoggedIn } = useSession()
  const isMeProfile =
    me && isLoggedIn && isSameAddress(me.address, voterAddress)

  const { data, isLoading } = useAddressHeaderQuery(
    {
      accountId: addressToAccountId(voterAddress, getMainnetReference()),
    },
    {
      enabled: Boolean(voterAddress),
    },
  )

  const handleCopyAddressClick = (): void => {
    onCopyAddress()

    toast({
      status: "success",
      title: `Address Copied`,
    })
  }

  const handleCopyProfileClick = (): void => {
    onCopyProfile()

    toast({
      status: "success",
      title: `Profile URL Copied`,
    })
  }

  useEffect(() => {
    if (origin) {
      const profileUrl = getProfileUrl()
      setValue(`${origin}${profileUrl}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [governor?.id, origin, setValue, voterAddress])

  if (isLoading) {
    return (
      <Stack
        align="center"
        as="section"
        mt={0}
        position="relative"
        w="full"
        {...stackProps}
      >
        <Skeleton h={36} w="full" />
      </Stack>
    )
  }

  if (!data) return null

  const isVerifiedByTwitter = Boolean(data?.account?.twitter)

  const getNameToDisplay = () => {
    if (overrideName) {
      return overrideName
    }

    return Boolean(data?.account?.name)
      ? truncate(data.account.name, 45)
      : shortString(voterAddress)
  }

  const nameToDisplay = getNameToDisplay()
  const displayName = isAddress(nameToDisplay)
    ? shortString(nameToDisplay)
    : nameToDisplay

  const tokenIds = organization?.tokenIds ?? []

  const isMultiChain = isMultiChainTokenIds(tokenIds)

  return (
    <Flex align="flex-start">
      <Stack direction="column" spacing={3} w="full">
        {/* Header - Desktop */}
        <Stack
          align="center"
          direction="row"
          display={onLargeDevice}
          justifyContent="space-between"
          spacing={3}
        >
          <HStack spacing={2}>
            <UserAvatar
              address={voterAddress}
              isVerifiedByTwitter={isVerifiedByTwitter}
              size="md"
              src={overridePicture ?? data?.account?.picture}
            />
            <Stack maxWidth="350px" pr={4}>
              <ChakraLink
                fontWeight="bold"
                href={ROUTES.profile(voterAddress)}
                textStyle={displayName.length > 20 ? "h4" : "h3"}
              >
                {displayName}
              </ChakraLink>
            </Stack>
            {slug && !isMultiChain
              ? tokenIds.map((tokenId, idx) => (
                  <DelegatingToTag
                    key={`delegating-to-${idx}`}
                    isMultiChain={isMultiChain}
                    slug={slug}
                    tokenId={tokenId}
                    voterAddress={voterAddress}
                  />
                ))
              : null}
          </HStack>
          {displayOptions ? (
            <Stack direction="row" display={onLargeDevice} spacing={3}>
              {isMeProfile ? (
                <Link
                  className="no-underline"
                  href={ROUTES.user.settings()}
                  prefetch={false}
                >
                  <Button
                    data-qa="profilepage-editprofile-btn"
                    variant="secondary"
                  >
                    Edit Profile
                  </Button>
                </Link>
              ) : null}

              {canDelegate ? (
                <ErrorBoundary section="AddressGovernanceDetails => ChooseDelegationButton">
                  <DelegateButton
                    account={data.account}
                    delegateAddress={voterAddress ?? ""}
                    organization={organization}
                    variant="primary"
                  >
                    Delegate
                  </DelegateButton>
                </ErrorBoundary>
              ) : null}

              <OptionsList
                displayName={displayName}
                profileUrl={profileUrl}
                onCopyProfileClick={handleCopyProfileClick}
              />
            </Stack>
          ) : null}
        </Stack>

        {/* Header - Mobile */}
        <Stack
          align="center"
          direction="row"
          display={onLittleDevice}
          justifyContent="space-between"
          spacing={3}
        >
          <Stack direction="column" spacing={4} width="100%">
            <HStack>
              <UserAvatar
                address={voterAddress}
                isVerifiedByTwitter={isVerifiedByTwitter}
                size="sm"
                src={data?.account?.picture ?? ""}
              />
              <ChakraLink
                fontWeight="bold"
                href={ROUTES.profile(voterAddress)}
                textStyle="h4"
              >
                {truncate(displayName, 20)}
              </ChakraLink>
              {displayOptions ? (
                <>
                  <Spacer />

                  <OptionsList
                    displayName={displayName}
                    profileUrl={profileUrl}
                    onCopyProfileClick={handleCopyProfileClick}
                  />
                </>
              ) : null}
            </HStack>

            {displayOptions &&
              slug &&
              !isMultiChain &&
              tokenIds.map((tokenId, idx) => (
                <DelegatingToTag
                  key={`mobile-delegating-to-${idx}`}
                  isMultiChain={isMultiChain}
                  slug={slug}
                  tokenId={tokenId}
                  voterAddress={voterAddress}
                />
              ))}
          </Stack>
          <Stack direction="row" display={onLargeDevice} spacing={3}>
            {isMeProfile ? (
              <Link
                className="no-underline"
                href={ROUTES.user.settings()}
                prefetch={false}
              >
                <Button variant="secondary">Edit Profile</Button>
              </Link>
            ) : null}

            {organization && canDelegate ? (
              <ErrorBoundary section="AddressGovernanceDetails => ChooseDelegationButton">
                <DelegateButton
                  account={data.account}
                  delegateAddress={voterAddress ?? ""}
                  organization={organization}
                  variant="primary"
                >
                  Delegate
                </DelegateButton>
              </ErrorBoundary>
            ) : null}
          </Stack>
        </Stack>

        {data?.account?.bio ? (
          <Text textStyle="body.regular.md">{data.account.bio}</Text>
        ) : null}

        {/* On Desktop */}
        <Stack
          align={{ base: "center", sm: "center" }}
          direction={{ base: "column", sm: "row" }}
          display={onLargeDevice}
          justifyContent="space-between"
          marginBottom={1}
          spacing={4}
          width="100%"
        >
          <HStack spacing={2}>
            {isAddress(voterAddress) ? (
              <HStack pl={0} width={{ base: "100%", sm: "auto" }}>
                <Text textStyle="body.bold.md" w="max-content">
                  {shortString(voterAddress)}
                </Text>
                <CopyButton
                  size={8}
                  tooltipLabel="address"
                  value={voterAddress}
                />
              </HStack>
            ) : null}
            {data?.account?.twitter ? (
              <SocialLink
                icon={<Icon as={TwitterIcon} />}
                link={data.account.twitter}
                name="Twitter"
              />
            ) : null}
          </HStack>
          {organization && displayEligibleBanner ? (
            <DelegationEligibleBanner
              justify="end"
              minWidth="350px"
              organization={organization}
              pr={2}
            />
          ) : null}
        </Stack>

        {/* On Mobile */}
        <Stack
          align={{ base: "center", sm: "center" }}
          direction={{ base: "column", lg: "row" }}
          display={onLittleDevice}
          justifyContent="space-between"
          marginBottom={1}
          spacing={4}
          width="100%"
        >
          {isAddress(voterAddress) || data?.account?.twitter ? (
            <Grid
              alignItems="center"
              display={onLittleDevice}
              gap={4}
              height="30px"
              templateColumns="repeat(3, 1fr)"
              width="full"
            >
              {isAddress(voterAddress) ? (
                <Button
                  px={0}
                  rightIcon={<Icon as={Copy} />}
                  variant="none"
                  onClick={handleCopyAddressClick}
                >
                  <Text textStyle="body.bold.md" w="max-content">
                    {shortString(voterAddress)}
                  </Text>
                </Button>
              ) : null}
              {data?.account?.twitter ? (
                <SocialLink
                  icon={<Icon as={TwitterIcon} />}
                  link={data.account.twitter}
                  name="Twitter"
                />
              ) : null}
            </Grid>
          ) : null}

          {displayOptions && isMeProfile ? (
            <Link
              className="no-underline"
              href={ROUTES.user.settings()}
              prefetch={false}
              width="100%"
            >
              <Button variant="secondary" width="100%">
                Edit Profile
              </Button>
            </Link>
          ) : null}

          {displayOptions && canDelegate ? (
            <ErrorBoundary section="AddressGovernanceDetails => ChooseDelegationButton">
              <DelegateButton
                account={data.account}
                delegateAddress={voterAddress}
                organization={organization}
                variant="primary"
                width="100%"
              >
                Delegate
              </DelegateButton>
            </ErrorBoundary>
          ) : null}

          {organization && displayEligibleBanner ? (
            <DelegationEligibleBanner organization={organization} />
          ) : null}
        </Stack>
      </Stack>
    </Flex>
  )
}

type OptionsListProps = {
  profileUrl: string
  displayName: string
  onCopyProfileClick: () => void
}
const OptionsList: FC<OptionsListProps> = ({
  profileUrl,
  displayName,
  onCopyProfileClick,
}) => {
  return (
    <Flex>
      <Menu>
        <MenuButton
          as={Button}
          bgColor="white"
          borderColor="gray.300"
          borderWidth="1px"
          px={3}
        >
          <Icon as={ShareNodes} fill="gray.600" />
        </MenuButton>
        <MenuList>
          <MenuItem>
            <Button
              data-qa="profilepage-btn-copyprofileurl"
              leftIcon={<Icon as={Copy} />}
              size="sm"
              variant="tertiary"
              onClick={onCopyProfileClick}
            >
              <Text>Copy Profile URL</Text>
            </Button>
          </MenuItem>

          <MenuItem>
            <ShareToFarcasterButton
              size="sm"
              title={`Tally | Profile | ${displayName}`}
              url={profileUrl}
              variant="tertiary"
            />
          </MenuItem>
          <MenuItem>
            <ShareToLensButton
              size="sm"
              title={`Tally | Profile | ${displayName}`}
              url={profileUrl}
              variant="tertiary"
            />
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  )
}

type SocialLinkProps = {
  icon?: ReactElement
  link: string
  name: string
}

const SocialLink: FC<SocialLinkProps> = ({ icon, link, name }) => {
  return (
    <ChakraLink
      isExternal
      background="gray.100"
      borderRadius="full"
      className="no-underline"
      href={EXTERNAL_ROUTES.twitter.handle(link)}
    >
      <HStack
        alignContent="center"
        alignItems="center"
        pb={1}
        pl={3}
        pr={3}
        pt={1}
        width={{ base: "100%", sm: "auto" }}
      >
        {icon}
        <Text textStyle="body.regular.md"> {name}</Text>
      </HStack>
    </ChakraLink>
  )
}

type DelegationToProps = {
  voterAddress: string
  isMultiChain: boolean
  slug: string
  tokenId: string
  onDelegateeFound?: () => void
}
export const DelegatingToTag: FC<DelegationToProps> = ({
  voterAddress,
  isMultiChain,
  tokenId,
  slug,
  onDelegateeFound,
}) => {
  const { data: addressDelegatingTo } = useAddressDelegatingToQuery(
    {
      delegateeInput: {
        address: voterAddress as string,
        tokenId,
      },
    },
    {
      enabled: Boolean(voterAddress) && Boolean(tokenId),
    },
  )

  const delegatee = addressDelegatingTo?.delegatee

  useEffect(() => {
    if (delegatee && onDelegateeFound) {
      onDelegateeFound()
    }
  }, [delegatee, onDelegateeFound])

  if (!delegatee) {
    return null
  }

  const decimals = delegatee?.token?.decimals

  const votes =
    delegatee?.votes && typeof decimals !== "undefined" && decimals >= 0
      ? getWeightLabel(BigNumber.from(delegatee?.votes), decimals)
      : ""

  const tokenSymbol = delegatee?.token?.symbol

  return (
    <HStack
      border="1px"
      borderColor="gray.200"
      borderRadius="lg"
      justify={{ base: "center", sm: "flex-start" }}
      pl={2}
      pr={2}
      width={{ base: "100%", sm: "auto" }}
    >
      {delegatee?.delegate?.address ? (
        <>
          <Text textStyle="sm">
            Delegating {votes} {tokenSymbol} to:
          </Text>
          <AddressInfo
            displayShortAddress
            account={delegatee?.delegate}
            label=""
            slug={slug}
            usePicture={false}
          />
          {isMultiChain ? <TokenChainIdentity tokenId={tokenId} /> : null}
        </>
      ) : (
        <Text textStyle="sm">Undelegated</Text>
      )}
    </HStack>
  )
}

type RegisterAsDelegateModalProps = {
  isOpen: boolean
  onClose: () => void
  address: string
  slug: string
  organization?: Organization
}
const RegisterAsDelegateModal: FC<RegisterAsDelegateModalProps> = ({
  isOpen,
  onClose,
  address,
  slug,
  organization,
}) => {
  const { origin } = useLocation()
  const profileRoute = ROUTES.governance.delegate.profile(slug, address)

  const {
    onCopy: onCopyProfile,
    setValue,
    value: profileRouteUrl,
  } = useClipboard(profileRoute)

  useEffect(() => {
    if (origin) {
      setValue(`${origin}${profileRoute}`)
    }
  }, [origin, profileRoute, setValue])

  const { toast } = useToast()

  const router = useRouter()
  const handleCloseClick = () => {
    router.replace(profileRoute)
    onClose()
  }

  const handleCopyProfileClick = (): void => {
    onCopyProfile()

    toast({
      status: "success",
      title: `Profile URL Copied`,
    })

    handleCloseClick()
  }

  const delegateMsg = useMemo(() => {
    if (organization) {
      const orgSettings = getDelegateOrgSettings(organization.id)

      if (orgSettings?.afterDelegateRegistration?.message) {
        return orgSettings.afterDelegateRegistration.message
      }
    }

    return "Share a link to your profile to start campaigning for votes"
  }, [organization])

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={handleCloseClick}
    >
      <ModalOverlay backdropFilter="blur(5px)" />
      <ModalContent
        maxWidth={{ base: "95%", md: "560px" }}
        top={{ base: "unset", lg: "120px" }}
      >
        <ModalHeader
          borderBottomColor="gray.100"
          borderBottomWidth={1}
          p={4}
          textStyle="h5"
        >
          <HStack>
            <Text>Congrats on your new profile</Text>
            <Text>&#127881;</Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody px={4} py={4}>
          <Stack spacing={4}>
            <Text fontSize="md" fontWeight="regular">
              <MarkdownViewer content={delegateMsg} />
            </Text>
            <Stack
              direction={{ base: "column", md: "row" }}
              justify="space-between"
            >
              <Text
                color="gray.600"
                fontSize="md"
                fontWeight="regular"
                maxWidth={{ base: "100%", md: "80%" }}
                overflow="hidden"
                pt={2}
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {profileRouteUrl}
              </Text>

              <Button
                px={4}
                variant="secondary"
                onClick={handleCopyProfileClick}
              >
                <Stack direction="row" spacing={2}>
                  <Icon as={Copy} />
                  <Text>Copy</Text>
                </Stack>
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
